const Items = []
const Activity = {
  id: '',
  title: '',
  description: '',
  location: '',
  period: '',
  create_by: '',
  create_date: '',
  update_by: '',
  update_date: '',
  form_id: '',
  form: {
    id: '',
    title: ''
  }
}
import API from '@/api'
import { fetchGet, fetchPost, defaultCatch } from './fetch'

// const url = API.getAPIUrl('/activity')
const getActivityById = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing activity id'))
  }
  const apiURL = API.getAPIUrl(`/activity/${id}`)
  return fetchGet(apiURL, {})
    .then((activity) => {
      return Promise.resolve(activity)
    })
    .catch(defaultCatch)
}

const getActivity = (q, offset, limit) => {
  const apiURL = API.getAPIUrl(`/activity`)
  return fetchGet(apiURL, { q, limit, offset })
    .then((activity) => {
      activity.map((a) => {
        a.location = `${a.location}`
        a.lecturer =
          a.lecturers.length > 1
            ? `${a.lecturers[0].first_name} ${a.lecturers[0].last_name} ...`
            : `${a.lecturers[0].first_name} ${a.lecturers[0].last_name}`
        a.form = { id: a.form_id, title: a.form_title, data: a.form_data }
        return a
      })
      return Promise.resolve(activity)
    })
    .catch(defaultCatch)
}

const getActivityByCourse = (courseId) => {
  const apiURL = API.getAPIUrl(`/activity/${courseId}/getByCourse`)
  return fetchGet(apiURL)
    .then((activities) => {
      return Promise.resolve(activities)
    })
    .catch(defaultCatch)
}

const newActivity = (data) => {
  const apiURL = API.getAPIUrl(`/activity/save`)
  return fetchPost(apiURL, data)
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch(defaultCatch)
}

const updateActivity = (id, data) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing activity id'))
  }
  const apiURL = API.getAPIUrl(`/activity/${id}/save`)
  return fetchPost(apiURL, data)
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch(defaultCatch)
}

const deleteActivity = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing activity id'))
  }
  const apiURL = API.getAPIUrl(`/activity/${id}/delete`)
  return fetchGet(apiURL, {})
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch(defaultCatch)
}

export {
  Activity,
  Items,
  getActivity,
  getActivityById,
  getActivityByCourse,
  newActivity,
  updateActivity,
  deleteActivity
}
