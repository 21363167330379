const FormItem = {
  id: '',
  title: '',
  description: '',
  data: '',
  report: '',
  form_type_id: '',
  form_type: {},
  create_by: '',
  update_by: ''
}
const Items = []
let FormTypes = []
import { fetch, fetchGet, defaultSuccess, defaultCatch } from './fetch'
import { getFormType } from './formType'
const url = '/api/v1/form'
const getFormById = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing form id'))
  }
  const urlById = `${url}/${id}`
  return fetchGet(urlById, {})
    .then((form) => {
      return Promise.resolve(form)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

const getForm = (q, offset, limit) => {
  return getFormType()
    .then((_formType) => {
      FormTypes = _formType
      return fetchGet(url, { q, limit, offset })
    })
    .then((forms) => {
      forms = forms.map((form) => {
        form = Object.assign({}, FormItem, form)
        form.form_type = FormTypes.find((item) => {
          return item.id == form.form_type_id
        })
        return form
      })
      return Promise.resolve(forms)
    })
    .catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
}
const getFormByTripId = (tripId) => {
  const apiUrl = `${url}/trip/${tripId}`
  return fetchGet(apiUrl, {})
    .then(defaultSuccess)
    .catch(defaultCatch)
}

const newForm = (data) => {
  const apiUrl = `${url}/save`
  return fetch(apiUrl, data)
}
const copyForm = (formId) => {
  const apiUrl = `${url}/${formId}/copy`
  return fetch(apiUrl)
}
const saveForm = (id, data) => {
  const urlAddForm = `${url}/${id}/save`
  return fetch(urlAddForm, data)
}
const saveReport = (id, data) => {
  const reportUrl = `${url}/${id}/report/save`
  return fetch(reportUrl, data)
}
export {
  FormTypes,
  FormItem,
  Items,
  getForm,
  getFormById,
  getFormByTripId,
  newForm,
  saveForm,
  saveReport,
  copyForm
}
