// Evaluation menu
const Menu = [
  // {
  //   text: 'หน้าหลัก',
  //   icon: 'home',
  //   to: {
  //     path: '/evaluation/welcome'
  //   }
  // },
  // {
  //   text: 'แบบประเมิน',
  //   icon: 'contacts',
  //   to: {
  //     path: '/evaluation/contact'
  //   }
  // },
  // {
  //   text: 'File',
  //   icon: 'insert_drive_file',
  //   to: {
  //     path: '/chat/media'
  //   }
  // },
  // {
  //   text: 'Settings',
  //   icon: 'settings',
  //   to: {
  //     path: '/chat/settings'
  //   }
  // }
]
import { fetchGet } from './fetch'
const url = '/api/v1/evaluation'

// get chat group
const getEvaluationById = (id) => {
  return id !== undefined ? [] : []
}

const newEvaluation = (data) => {
  const urlById = `${url}/evaluation/new`
  return fetchGet(urlById, data)
    .then((status) => {
      return Promise.resolve(status)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

export { Menu, getEvaluationById, newEvaluation }
