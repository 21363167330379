<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <!-- <v-col cols="12">
          <v-subheader>ฟอร์มการประเมิน</v-subheader>
        </v-col> -->
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-btn color="primary" @click="handleAddForm">เพิ่ม</v-btn>
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="หลักสูตร/วิทยากร/สถานที่"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :loading="loading"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :items-per-page-options="[
                  10,
                  25,
                  50,
                  { text: 'All', value: -1 }
                ]"
                class="elevation-1"
                item-key="id"
                v-model="complex.selected"
                @click:row="handleClick"
              >
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogForm" max-width="80vh">
      <v-card>
        <v-toolbar card>สร้างฟอร์มการประเมิน</v-toolbar>
        <v-card-text>
          <form>
            <v-text-field
              v-model="formModel.title"
              :counter="100"
              label="ชื่อ"
              required
            ></v-text-field>
            <v-select
              :items="formTypes"
              v-model="formModel.form_type_id"
              label="ประเภทข้อคำถาม"
              auto
              required
              item-text="title"
              item-value="id"
            ></v-select>
            <!-- <v-text-field
              v-model="formModel.email"
              label="รายลละเอียด"
              required
            ></v-text-field> -->
            <v-divider class="mt-3 mb-3"></v-divider>
            <v-btn :loading="loading" :disabled="loading" @click="handleSubmit"
              >บันทึก</v-btn
            >
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrDialog" max-width="60vh">
      <v-card>
        <v-toolbar card>QR Code</v-toolbar>
        <v-card-text>
          <VueQrcode :value="formModel.qrCodeUrl" />
          <h3>
            <a :href="formModel.qrCodeUrl">{{ formModel.qrCodeUrl }}</a>
          </h3>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from '@/api'
import VueQrcode from 'vue-qrcode'
import { getForm, newForm, saveForm, copyForm } from '@/api/form'
import { getFormType } from '@/api/formType'
export default {
  name: 'SurveyFormList',
  components: {
    VueQrcode
  },
  data() {
    return {
      isNew: false,
      loading: false,
      dialog: false,
      dialogForm: false,
      qrDialog: false,
      formTypes: [],
      formModel: {
        id: '',
        name: '',
        qrCodeUrl: ''
      },

      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'ฟอร์ม',
            value: 'title'
          },
          {
            text: 'ประเภทคำถาม',
            value: 'form_type.title'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        {
          text: 'QR Code',
          icon: 'mdi-qrcode',
          click: this.handleClickQrCode
        },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        },
        {
          text: 'ออกแบบฟอร์ม',
          icon: 'mdi-drawing',
          click: this.handleDesignFormItem
        },
        {
          text: 'ออกแบบรายงาน',
          icon: 'mdi-chart-areaspline',
          click: this.handleDesignReport
        },
        {
          text: 'คัดลอกแบบฟอร์ม',
          icon: 'mdi-content-copy',
          click: this.handleCopyFormItem
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      getFormType()
        .then((_formTypes) => {
          this.formTypes = _formTypes
          return getForm('', 0, 100)
        })
        .then((forms) => {
          this.complex.items = forms
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAddForm() {
      this.editMode = false
      this.dialogForm = true
    },
    handleCopyFormItem(row) {
      this.loading = true
      copyForm(row.id)
        .then((form) => {
          this.$notify({
            type: 'success',
            group: 'notify',
            title: 'คัดลอกข้อมูลเรียบร้อย'
          })
          this.complex.items.push(form)
          this.dialogForm = false
          this.formModel = {}
        })
        .catch((error) => {
          console.error(new Error(error))
          this.$notify({
            type: 'error',
            group: 'notify',
            title: 'เกิดข้อผิดพลาด',
            text: error.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClickQrCode(row) {
      //row
      this.formModel = Object.assign(this.formModel, row)
      this.formModel.qrCodeUrl = API.getBaseUrl(`/f/${row.id}`)
      this.qrDialog = true
    },
    handleClick(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.$router.push(`/surveyForm/${row.id}/edit`)
    },
    handleDesignReport(row) {
      this.$router.push(`/surveyForm/${row.id}/designReport`)
    },
    handleViewItem(row) {
      this.editMode = false
      this.formModel = Object.assign(this.formModel, row)
    },
    handleEditItem(row) {
      this.editMode = true
      this.formModel = Object.assign(this.formModel, row)
      this.dialogForm = true
    },
    handleDesignFormItem(row) {
      this.handleClick(row)
      this.dialogForm = true
      this.formModel = Object.assign(this.formModel, row)
    },
    // handleDeleteItem() {},
    handleSubmit() {
      if (this.editMode) {
        saveForm(this.formModel.id, {
          title: this.formModel.title,
          form_type_id: this.formModel.form_type_id
        }).then(() => {
          this.$notify({
            type: 'success',
            group: 'notify',
            title: 'บันทุกข้อมูลเรียบร้อย'
          })
          const _formType = this.formTypes.find(
            (item) => this.formModel.form_type_id == item.id
          )
          this.formModel.form_type = _formType
          this.complex.items = this.complex.items.map((item) => {
            return item.id == this.formModel.id ? this.formModel : item
          })
          this.loading = false
          this.dialogForm = false
          this.formModel = {}
        })
      } else {
        newForm({
          title: this.formModel.title,
          form_type_id: this.formModel.form_type_id
        })
          .then((form) => {
            this.$notify({
              type: 'success',
              group: 'notify',
              title: 'บันทึกข้อมูลเรียบร้อย'
            })
            this.complex.items.push(form)
            this.dialogForm = false
            this.formModel = {}
          })
          .catch((error) => {
            console.error(new Error(error))
            this.$notify({
              type: 'error',
              group: 'notify',
              title: 'เกิดข้อผิดพลาด',
              text: error.message
            })
          })
          .finally(() => {})
      }
    },
    handleResponseNewForm() {}
  }
}
</script>
