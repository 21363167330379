import { fetch } from './fetch'
const User = {
  id: '',
  uuid: '',
  first_name: '',
  last_name: '',
  avatar: '',
  facebook: '',
  phone: ''
}
const Items = []
// const userAuth = Items.map((value) => {
//   value.password = '111111'
//   return value
// })

const getUserById = (uuid) => {
  return uuid === undefined ? Items[0] : Items.find((x) => x.uuid === uuid)
}

const getUser = (limit) => {
  return limit ? Items.slice(0, limit) : Items
}

const login = (username, password) => {
  // let user = null
  if (username === undefined || password === undefined) {
    return false
  }
  const url = '/api/v1/login'
  return fetch(url, { username, password })
    .then((user) => {
      user.fullname = `${user.first_name} ${user.last_name}`
      return Promise.resolve(user)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

export { User, Items, getUser, getUserById, login }
