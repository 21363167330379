const Items = []
// Array(30)
//   .fill({
//     id: '1',
//     name: 'A1-Good neighbors (กทม ครึ่งวัน)',
//     lecturer: 'สมชาย มาดี',
//     location: {
//       province: 'กรุงเทพ',
//       build: '',
//       room: '',
//       lat: '',
//       lng: ''
//     },
//     time_period: 600
//   })
//   .map((value, index) => {
//     value.id = index + 1
//     value.name = `A${value.id}-Good neighbors (กทม ครึ่งวัน)`
//     value.time_period = value.time_period + index
//     return value
//   })
const Courses = {
  id: '',
  code: '',
  title: '',
  level: '',
  objective: '',
  condition: '',
  description: '',
  location: '',
  period: '',
  create_by: '',
  create_date: '',
  update_by: '',
  update_date: ''
}
const CoursesLevel = ['พื้นฐาน', 'กลาง', 'เชี่ยวชาญ']

import { fetch, fetchPost, fetchGet } from './fetch'
const url = '/api/v1/courses'

const newCourses = (data) => {
  const apiUrl = `${url}/save`
  return fetchPost(apiUrl, data)
    .then((courses) => {
      return Promise.resolve(courses)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const updateCourses = (id, data) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing courses id'))
  }
  const apiUrl = `${url}/${id}/save`
  return fetchPost(apiUrl, data)
    .then((courses) => {
      return Promise.resolve(courses)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const deleteCourses = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing courses id'))
  }
  const urlById = `${url}/${id}/delete`
  return fetchPost(urlById, {})
    .then((courses) => {
      return Promise.resolve(courses)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}
const getCourseById = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing Course id'))
  }
  const urlById = `${url}/${id}`
  return fetchGet(urlById, {})
    .then((course) => {
      return Promise.resolve(course)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

const getCourse = (q, limit, offset) => {
  return fetchGet(url, { q, limit, offset })
    .then((courses) => {
      return Promise.resolve(courses)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const updateActivities = (courseId, activityIdList) => {
  const apiUrl = `${url}/${courseId}/updateActivity`
  return fetch(apiUrl, activityIdList)
}

const syncData = (apiKey) => {
  const apiUrl = `/api/v1/sync/courses`
  const headers = { Authorization: apiKey }
  return fetchGet(apiUrl, {}, headers)
    .then((fetched) => {
      return Promise.resolve(fetched)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export {
  Items,
  Courses,
  CoursesLevel,
  getCourse,
  newCourses,
  updateCourses,
  deleteCourses,
  getCourseById,
  updateActivities,
  syncData
}
