// implement your own methods in here, if your data is coming from A rest API

import * as Auth from './auth'
import * as User from './user'
import * as Activity from './activity'
import * as Form from './form'
// import * as Chat from './chat'
// import * as Files from './file'
// import * as Mail from './mail'
// import * as Post from './post'
// import * as Chart from './chart'
// import * as Project from './project'
import * as Dashboard from './dashboard'
import * as Course from './course'
import * as Lecturer from './lecturer'
import * as Evaluation from './evaluation'
// import * as Group from './group'

const getBaseUrl = (path) => {
  const host = process.env.VUE_APP_BASE_URL
  if (path) {
    return `${host}${path}`
  }
  return host
}
const getLuUrl = (path) => {
  const host = process.env.VUE_APP_LU_URL
  if (path) {
    return `${host}${path}`
  }
  return host
}

const getAPIUrl = (path) => {
  const host = process.env.VUE_APP_API_URL
  let urlPrefix = ''
  if (!process.env.VUE_APP_API_URL.match('/api/v1') && !path.match('/api/v1')) {
    urlPrefix = '/api/v1'
  }
  if (path) {
    return `${host}${urlPrefix}${path}`
  }

  return host
}

export default {
  // url
  getLuUrl,
  getBaseUrl,
  getAPIUrl,
  // auth
  login: Auth.login,
  // dashboard
  getDashboard: Dashboard.getDashboard,
  // course
  getCourse: Course.getCourse,
  getCourseById: Course.getCourseById,
  // course
  getForm: Form.getForm,
  getFormById: Form.getFormById,
  saveForm: Form.saveForm,
  // lecturer
  getLecturer: Lecturer.getLecturer,
  getLecturerById: Lecturer.getLecturerById,
  // user
  getUser: User.getUser,
  getUserById: User.getUserById,
  // Evaluation
  getEvaluationMenu: Evaluation.Menu,
  // project
  // getProject: Project.getProject,
  // activity
  getActivity: Activity.getActivity
  // post
  // getPost: Post.getPost,
  // chat
  // getChatMenu: Chat.Menu,
  // getChatGroup: Chat.Groups,
  // getChatGroupById: Chat.getChatById,
  // FIle
  // getFile: Files.getFile,
  // getFileMenu: Files.getFileMenu,
  // mail
  // getMail: Mail.getMail,
  // getMailMenu: Mail.MailMenu,
  // getMailById: Mail.getMailById,
  // getMailByType: Mail.getMailByType,
  // chart data
  // getMonthVisit: Chart.monthVisitData,
  // getCampaign: Chart.campaignData,
  // getLocation: Chart.locationData
}
