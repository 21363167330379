const Items = {
  montly: {
    group: 2,
    member: 100,
    survey: 30,
    surveyed: 20
  }
}

import { fetchGet } from './fetch'
const url = '/api/v1/dashboard'

const getDashboard = () => {
  return fetchGet(url, {})
    .then((dashboard) => {
      return Promise.resolve(dashboard)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export { Items, getDashboard }
