const FormTypeItem = {
  id: '',
  name: '',
  create_by: '1',
  create_date: '2020-11-05 00:00:00',
  update_by: '',
  update_date: ''
}
const Items = []
// , fetchGet, defaultSuccess, defaultCatch
import { fetch, fetchGet } from './fetch'
const url = '/api/v1/formType'

const getFormTypeById = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing form type id'))
  }
  // const urlById = `${url}/${id}`
  return Promise.resolve(Items.find((x) => x.id === id))
  // return fetchGet(urlById, {})
  //   .then((form) => {
  //     return Promise.resolve(form)
  //   })
  //   .catch((error) => {
  //     return Promise.reject(error)
  //   })
}

const getFormType = (q, limit, offset) => {
  limit = 100
  offset = 0
  return fetchGet(url, { q, limit, offset })
    .then((form) => {
      return Promise.resolve(form)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
}

const newFormType = (data) => {
  const apiUrl = `${url}/save`
  return fetch(apiUrl, data)
}

const saveFormType = (id, data) => {
  const urlAddForm = `${url}/${id}/save`
  return fetch(urlAddForm, data)
}
export {
  FormTypeItem,
  Items,
  getFormType,
  getFormTypeById,
  newFormType,
  saveFormType
}
